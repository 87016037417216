section.postDetail{
  background-color: #efefef;
  padding: 10px 0 50px;

  h2{
    font-weight: bold;
    @include fontsize(50);
    margin: 0 0 10px;
    i{
      margin-right: 10px;
      color: #fd8c7a;
    }
  }

  .postDblock{
    background-color: #fff;
    height: auto;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;

      display: block;
      padding: 20px 20px 10px;
      height: 100%;
      background-color: #fff;
      color: #333;


      dl{
        margin-right: 100px;
        @media #{$small} {
          margin-right: 0;
        }
        dt{
          float: left;
          @include fontsize(60);
          color: #ffb966;
          margin-top: -10px;

        }
        dd{
          margin-left: 50px;
          ul{
            li{
              &.qTtl{
                font-weight: bold;
                @include fontsize(24);
                line-height: 1.5;
                margin-bottom: 10px;

              }
              &.qDetail{
                @include fontsize(20);
                line-height: 1.8;


              }

            }
          }


        }
      }


    .new_icon{
      position: absolute;
      top:-5px;
      right: 20px;
      width: 20px;
    }





  }

  ul{


    li{
      margin-bottom: 5px;
      @media #{$small} {
        display: inline-block;
      }





      &.postname{
        font-weight: bold;
        @include fontsize(18);
        color: #909090;
        margin-top: 10px;


      }
      &.posttime{
        font-weight: bold;
        @include fontsize(18);
        color: #909090;
      }
      &.nicepoint{
        @include fontsize(18);
        color: #ffa861;
        span{
          font-weight: bold;
          margin-left: 5px;
        }
      }
      i{
        margin-right: 5px;
      }

    }

  }


  .postDblock:after {
    content: " ";
    width: 95%;
    border-bottom: solid 8px #898989;
    position: absolute;
    bottom: -8px;
    margin: 0 0 0 10px;

  }

  .partnerRes2{

    width: 100%;
    text-align: center;
    background-color: #337ab7;
    color: #fff;
    @include fontsize(20);
    padding: 3px 0;
    margin-bottom: 30px;
    span{
      font-weight: bold;
      margin-left: 5px;
    }
  }



}

p.listBack {
  a {
    color: #fd8c7a;
    @include fontsize(24);
    border-radius: 50px;
    padding: 10px;
    border: 1px solid #fd8c7a;
    margin: 0 auto 60px;
    display: block;
    text-align: center;
    background-color: #fff;
    width: 50%;
    &:hover {
      background-color: #fd8c7a;
      color: #fff;
      text-decoration: none;
    }
  }


}