section.topPickUp{
  background-color: #efefef;
  padding: 60px 0;
  h2{
    font-weight: bold;
    @include fontsize(50);
    margin: 0 0 10px;
    i{
      margin-right: 10px;
      color: #fd8c7a;
    }
  }
  p.p_cate_btn{

    a{
      display: block;
      border-radius: 50px;
      color: #000;
      background-color: #fff;
      padding: 10px 0;
      text-align: center;
      &:hover{
        background-color: #CDCDCD;
        color: #fff;
        text-decoration: none;
      }
    }
  }
  p.current{
    display: block;
    border-radius: 50px;
    color: #fff;
    background-color: #ffb966;
    padding: 10px 0;
    text-align: center;
    font-weight: bold;
  }


}

