// mixin
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}


/*ベースフォントサイズ*/
@mixin fontsize($size: 24, $base: 16) {
  font-size: $size + px;
  font-size: ($size / $base) * 1rem;
}
