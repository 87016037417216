section.whatsAsk{
  h2{
    font-family: "Open Sans";
    font-weight: bold;
    @include fontsize(60);
    text-align: center;
  }
  p.lead{
    text-align: center;
    color: #808080;
    @include fontsize(20);
    margin-bottom: 10px;

  }
  p.btn_detail{
    a{
      color: #fd8c7a;
      @include fontsize(20);
      border-radius: 50px;
      padding: 10px;
      border: 1px solid #fd8c7a;
      margin: 0 auto;
      display: block;
      text-align: center;
      width: 20%;
      background-color: #fff;
      &:hover{
        background-color: #fd8c7a;
        color: #fff;
        text-decoration: none;
      }


    }

  }

}