/*
Theme Name: AskPartners
*/
$small:  "only screen and (max-width:768px)";
$middle: "only screen and (min-width:768px) and (max-width:1108px)";
$large:  "only screen and (min-width:1108px)";


// それぞれのメディアクエリで下記を扱う
//
//@media #{$small} {
//  //640px以下
//}
//
//@media #{$middle} {
//  //641px〜1200pxまで
//}
//
//@media #{$large} {
//  //1200px以上
//}
//

// ベースの色を設定
// この色基準で濃くしたらり（darken）、薄くしたり（lighten）出来る
$base-color: #1e1e1d;


body{
  font-family: "メイリオ" , Meiryo , Avenir , "Open Sans" , "Helvetica Neue" , Helvetica , Arial , Verdana , Roboto , "游ゴシック" , "Yu Gothic" , "游ゴシック体" , "YuGothic" , "ヒラギノ角ゴ Pro W3" , "Hiragino Kaku Gothic Pro" , "Meiryo UI" , "ＭＳ Ｐゴシック" , "MS PGothic" , sans-serif;
}


// mixin 読み込み　よく使う関数なんかを格納
@import "mixin";

// それぞれのパーツ別でファイルを作成
@import "header";
@import "eye-catch";
@import "btn";
@import "footer";
@import "common";
@import "bread-crumb";
@import "pagination";
@import "editor";
@import "front-page";
@import "whatsAsk";
@import "pickup";
@import "ranknew";
@import "pagettl";
@import "categoryDetail";
@import "pageing";
@import "postDetail";
@import "partnerAnswer";
@import "partnerList";
@import "partnerDetail";

/* html,bodyタグの設定 */
html, body{
  margin: 0;         /* 余白の削除 */
  padding: 0;        /* 余白の削除 */
  width:100%;
  height:100%;


}

a {
  transition: 0.5s ;
}

.breadcrumb {
  padding: 20px 0 10px;
  border-bottom: 1px solid #fff;
  list-style: none;
  background-color: transparent;
  border-radius: 0px;
  @include fontsize(18);
  a{
    color: #fff;
  }
}
.breadcrumb>.active {
  color: #fadec7;
}

.breadcrumb>li+li:before {
  color: #fadec7;
}



.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;

  @media #{$small} {
    display: block;
  }
  .panel{
    height: 97%;
    margin-bottom: 3%;
    background-color: #fff;
    box-shadow: none;
    border-radius: 0px;

    a{
      display: block;
      padding: 20px;
      height: 100%;
      background-color: #fff;
      color: #333;
      &:hover{
        background-color: #0b0b0b;
        text-decoration: none;
        color: #fff;
      }

      dl{
        dt{
          float: left;
          @include fontsize(60);
          color: #ffb966;
          margin-top: -10px;

        }
        dd{
          margin-left: 50px;
          ul{
            li{
              &.qTtl{
                font-weight: bold;
                @include fontsize(24);
                line-height: 1.5;
                margin-bottom: 10px;

              }
              &.qDetail{
                @include fontsize(20);
                line-height: 1.5;


              }

            }
          }
          .postCategory{
            position: absolute;
            bottom: 10px;
            ul{
              li{
                display: inline-block;
                &.postDay{
                  @include fontsize(16);
                  i{
                    margin-right: 5px;
                  }
                }
                &.categoryName{
                  @include fontsize(14);
                  background-color: #898989;
                  border-radius: 50px;
                  color: #fff;
                  padding: 0px 5px;

                }
                &.nicePoint{
                  @include fontsize(18);
                  color: #ffb966;
                  font-weight: bold;
                  i{
                    margin-right: 0px;
                  }

                }
              }

            }
          }

        }
      }

    }
    .new_icon{
      position: absolute;
      top:-5px;
      right: 40px;
      width: 20px;
    }



  }
  .panel:after {
    content: " ";
    width: 80%;
    border-bottom: solid 8px #CDCFD7;
    position: absolute;
    bottom: -2px;
    margin: 0  0 0 25px;
  }


    }















