section.ranknew{
  background-image: url("images/ranknew.png");
  background-position: center;
  background-size: cover;
  position: relative;
  padding: 60px 0;

  h2{
    font-weight: bold;
    @include fontsize(50);
    margin: 0 0 10px;
    color: #fff;
    i{
      margin-right: 10px;
    }
  }

  .rnblock{
    background-color: #fff;
    height: 150px;
    position: relative;
    margin-top: 20px;


    a{
      display: block;
      padding: 20px;
      height: 100%;
      background-color: #fff;
      color: #333;
      &:hover{
        background-color: #0b0b0b;
        text-decoration: none;
        color: #fff;
      }

      dl{
        dt{
          float: left;
          @include fontsize(60);
          color: #ffb966;
          margin-top: -10px;

        }
        dd{
          margin-left: 50px;
          ul{
            li{
              &.qTtl{
                font-weight: bold;
                @include fontsize(24);
                line-height: 1.5;
                margin-bottom: 10px;

              }
              &.qDetail{
                @include fontsize(20);
                line-height: 1.5;


              }

            }
          }
          .postCategory{
            position: absolute;
            bottom: 5px;
            ul{
              li{
                display: inline-block;
                &.postDay{
                  @include fontsize(16);
                  i{
                    margin-right: 5px;
                  }
                }
                &.categoryName{
                  @include fontsize(14);
                  background-color: #898989;
                  border-radius: 50px;
                  color: #fff;
                  padding: 0px 5px;

                }
                &.nicePoint{
                  @include fontsize(18);
                  color: #ffb966;
                  font-weight: bold;
                  i{
                    margin-right: 0px;
                  }

                }
              }

            }
          }

        }
      }

    }
    .new_icon{
      position: absolute;
      top:-5px;
      right: 20px;
      width: 20px;
    }

    .ranking{
      position: absolute;
      bottom:0px;
      right: 30px;
      p{
        @include fontsize(48);
        font-weight: bold;
        color: #dad9d9;
        margin: 0;
      }

    }

  }

  .rnblock:after {
    content: " ";
    width: 90%;
    border-bottom: solid 8px #898989;
    position: absolute;
    bottom: -8px;
    margin: 0  0 0 25px;

  }

}