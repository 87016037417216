section.common_f_contents{
  background-color: #fff;
  padding:20px 0 20px;
  text-align: center;
  h2{
    color: #000;
    @include fontsize(48);
    font-weight: bold;
  }
  p.f_cate_btn{
    a{
      display: block;
      border-radius: 50px;
      border: 1px solid #cdcdcd;
      color: #000;
      background-color: #fff;
      padding: 10px 0;
      &:hover{
        background-color: #CDCDCD;
        color: #fff;
        text-decoration: none;
      }
    }
  }
  p.f_cata_link{
    a{
      @include fontsize(24);
      text-decoration: underline;
      &:hover{
        color: #fd8c7a;
        text-decoration: underline;
      }
    }
  }
}

.footer {
  width: 100%;
  height: auto;
  background-color: #fff;


  ul {
    padding: 30px 0 10px;
    position:relative;
    text-align: center;

    li {
      border-bottom: 1px solid #000;

      @media #{$large} {
        display: inline-block;
        margin-right: 0px;
        margin-bottom: 0px;
        border-bottom: none;
        border-right: 1px solid #ccc;
        position:relative;
        padding: 0px 30px;

      }
      a {
        color: #808080;
        &:hover {
          color: #fd8c7a;
          text-decoration: underline;
        }
      }
    }
    li:last-child {
      border-right: none;
    }
  }
  p.footer_logo{
    img{
      width: 300px;
      margin: 0 auto;
    }
  }
  p.footer_lead{
    @include fontsize(12);
    text-align: center;
    color: #808080;
    letter-spacing: 2px;
    margin-bottom: 40px;



  }
  p.copyright{
    text-align: center;
    color: #808080;
    margin-bottom: 50px;
    a{
      color: #808080;

    }
  }

}

br.clear{
  clear: both;
}

#top-bar{
  display: none;
}





.fixed-bottom {
  position: fixed;
  bottom: 0px;
}


 #bottom-bar {
  width: 100%;
  background-color: #000;
  line-height: 45px;
   @include fontsize(30);
  font-weight: bold;
  //z-index: 2;
  margin: 0;
  text-align: center;

   .f_btn_obi{
     background: linear-gradient(-135deg, #ffa15f, #ffcd6c);
     display: block;
     line-height: 30px;
     color: #fff;
     @include fontsize(24);
     font-weight: normal;
     letter-spacing: 2px;

   }

   p.f_btn_search{
     a{
       display: block;
       background: linear-gradient(-135deg, #50bba3, #9cdf86);
       color: #fff;
       border-radius: 50px;
       text-decoration: none;
       letter-spacing: 2px;

       &:hover{
         background: linear-gradient(-135deg, #69fcdb, #aefe93);
         color: #fff;
         text-decoration: none;
       }
       i{
         margin-right: 15px;
       }
     }

   }

   p.f_btn_post{
     a{
       display: block;
       background: linear-gradient(-135deg, #4b91c8, #5cc1c0);
       color: #fff;
       border-radius: 50px;
       text-decoration: none;
       letter-spacing: 2px;

       &:hover{
         background: linear-gradient(-135deg, #5eb6fc, #78faf9);
         color: #fff;
         text-decoration: none;
       }
       i{
         margin-right: 15px;
       }
     }

   }
}

#pageTop {
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 10;
}

#pageTop a {
  display: block;
  z-index: 999;
  padding: 8px 0 0 0;
  border-radius: 30px;
  width: 35px;
  height: 35px;
  background-color: #efefef;
  color: #000;

  font-weight: bold;
  text-decoration: none;
  text-align: center; }

#pageTop a:hover {
  text-decoration: none;
  opacity: 0.7; }

