header {
  background-color: #f5f5f5;

  h1 {
    margin-top: 10px;
    margin-bottom: 5px;
    img{
      width: 200px;
      margin: 0 auto;
    }
  }

  p.head_lead{
    @include fontsize(12);
    text-align: center;
    color: #808080;
    letter-spacing: 1px;
    margin-bottom: 0px;
    margin-top: 5px;



  }

  .nav_btn{
    img{
    height: 60px;
    }
  }

  ul.head_link{
    li{
      float: left;
      a{
        border-left: 1px solid #f5f5f5;
        background-color: #fff;
        color: #000000;
        @include fontsize(24);
        display: block;
        padding: 19px 30px;
        &:hover{
          background-color: #CDCDCD;
          color: #fff;
          text-decoration: none;
        }

      }
      i{
        margin-right: 5px;
      }
    }
  }

}

/* ヘッダーの固定 */
div#header-fixed
{
  position: fixed;            /* ヘッダーの固定 */
  top: 0;                   /* 位置(上0px) */
  left: 0;                  /* 位置(右0px) */
  width: 100%;                /* 横幅100%　*/
  z-index: 1;
}

div#header-bk {
  background-color:#f5f5f5;     /* 背景色(黒) */
  padding:0;       /* 上10px、下20pxをあける */
  height:60px;              /* 縦の高さ110px */
  width:100%;                /* 横の幅を100% */
}

div#header_in {
  height: 100%;              /* 縦の表示領域はheader-bkと同じ */
  width:100%;              /* 横の幅970px */
}

.modal-backdrop {
  z-index: 0;
}

.modal-dialog{
  z-index: 1500;
}

.modal{
  z-index: 1500;
}



