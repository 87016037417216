
.clearfix:after {
  content:" ";
  display:block;
  clear:both;
}

/** margin bottom **/
@for $i from 1 through 8 {
  .mb#{$i*5} {
    margin-bottom: #{$i*5}px;
  }
}

/* Bootstrapのガター幅を調整するための汎用CSS */
@for $i from 0 through 5 {
  .row-#{$i*10} > div {
    padding-right: #{$i*5}px;
    padding-left: #{$i*5}px;
  }
}



ul {
  padding: 0;
  li {
    padding: 0;
    list-style: none;
  }
}



section {
  &.frontContent {
    padding: 50px 0;
    &:nth-child(odd) {
      background-color: #f5f5f5;
    }
    h2 {
      text-align: center;
    }
  }
  &.content {
    padding: 50px 0;
    h2 {
      text-align: center;
    }
  }
}


.serviceList {
  a {
    color: #4b5053;
    display: block;
    &:hover {
      text-decoration: none;
      .serviceThumb {
        overflow: hidden;
        img {
          transition: transform 0.5s linear;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
    h3 {
      @include fontsize(30);
    }
    p {
      @include fontsize(20);
    }
    ul {
      li {
        display: inline-block;
        background-color: #757D80;
        color: #FFF;
        padding: 2px 5px;
        @include border-radius(3px);
        @include fontsize(20);
      }
    }
  }

}

.newsList {
  @include fontsize(24);
  @media #{$middle} {
    @include fontsize(20);
  }
  @media #{$large} {
    @include fontsize(30);
  }
  li {
    padding: 10px 0;
    border-bottom: 1px dotted #dddddd;
  }
  span {
    &.newsCategory {
      background: #4b5053;
      color: #FFF;
      display: inline-block;
      padding: 0 10px;
      @include border-radius(5px);
      font-size: 90%;
    }
  }
}


.googleMap {
  border: 5px solid #DDDDDD;
  background-color: #DDDDDD;
  padding: 10px;
  height: 200px;
}

.companyDetail {
  ul {
    li {
      padding: 10px 0;
      border-bottom: 1px dotted #CCCCCC;
      dl {
        margin-bottom: 0;
        dt, dd {
        }
      }
    }
  }
}



label {
  &.checkboxList {
    font-weight: normal;
    display: block;
  }
}

.contactTitle {
  @include fontsize(20);
  font-weight: bold;
}


.required {
  color: #ff0000;
}

.checkbox {
  display: inline-block;
}
