.pageing{

  ul {
    padding: 50px 0 0px;
    position:relative;
    text-align: center;

    li {
        display: inline-block;
        margin-right: 0;
        margin-bottom: 0;
        position:relative;
      @include fontsize(20);



      a {
        color: #fff;
        background-color: #ffb966;
        padding: 10px 15px;
        &:hover {

          text-decoration: none;
          color: #fff;
          background-color: #0b0b0b !important;
        }
      }
      &.current{
        padding: 10px 15px;
        background-color: #fff;
      }
    }
    li:last-child {

    }
  }

}