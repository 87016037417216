.partnerAnswer{
  border-bottom:  1px solid #cdcfd7;
  padding-bottom: 30px;
  margin-bottom: 30px;


  dl{
    margin-bottom: 0;
    text-align: center;
    dt{
      margin-bottom: 10px;
      img{
      width: 50px;
      }

    }
    dd{
      color: #ffa861;
      font-weight: bold;
      @include fontsize(20);
      ul{
        li{
          @media #{$small} {
            display: inline-block;
          }

        }
      }

    }
  }
  p.profile_btn{
    margin-top: 0em;
    text-align: center;
    a{
      border: 1px solid #000;
      padding:5px 10px;
      border-radius: 50px;
      @include fontsize(18);
      color: #000;


      &:hover{
        background-color: #ffa861;
        border: 1px solid #ffa861;
        color: #fff;
        text-decoration: none;

      }
    }

  }

  .pcomment {
    background-color:#fff;
    border:2px solid #ffa861;
    border-radius:5px;
    position:relative;
    padding: 0 10px;
    @include fontsize(24);
    line-height: 1.5;
    margin-right: 30px;
    margin-bottom: 10px;
  }

  .pcomment:before {
    border:10px solid transparent;
    border-right-color:#fff;
    border-left-width:0;
    left:-7px;
    content:"";
    display:block;
    top:10px;
    position:absolute;
    width:0;
    z-index:1;
  }

  .pcomment:after {
    border:10px solid transparent;
    border-right-color:#ffa861;
    border-left-width:0;
    left:-10px;
    content:"";
    display:block;
    top:10px;
    position:absolute;
    width:0;
  }

  span.att{
    color: #999;
    @include fontsize(18);
  }

  p.anstime{
    @include fontsize(18);
    color: #000;
    i{
      margin-right: 5px;
    }
  }



}

