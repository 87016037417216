section.partnerList{
  background-color: #efefef;
  padding: 10px 0 30px;

  .row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;

    @media #{$small} {
      display: block;
    }
    .panel{
      height: 97%;
      margin-bottom: 3%;
      background-color: #fff;
      box-shadow: none;
      border-radius: 0px;

      a{
        display: block;
        padding: 20px;
        height: 100%;
        background-color: #fff;
        color: #333;
        &:hover{
          background-color: #0b0b0b;
          text-decoration: none;
          color: #fff;
        }

        dl.partnerInfo{
          border-bottom: 1px solid #ccc;
          dt{
            float: left;
            margin-top: -5px;
            text-align: center;
            img{
              width: 60px;
            }
            p{
              @include fontsize(20);
              margin-top: 5px;
              margin-bottom: 10px;
              span{
                margin-left: 5px;
              }
              i{
                margin-right: 5px;
              }
            }

          }
          dd{
            margin-left: 120px;

            ul{
              li{
                &.furigana{

                  @include fontsize(14);
                  margin-bottom: 0px;
                  margin-top: 10px;

                }
                &.pName{
                  @include fontsize(50);
                  font-weight: bold;
                  margin-bottom: 30px;



                }

              }
            }


          }
        }

        dl.partnerProfile{
          margin-bottom: 0;

          dt{
            @include fontsize(22);

            float: left;
            margin-top: 0;
            text-align: center;
            margin-bottom: 10px;



          }
          dd{
            @include fontsize(22);
            margin-left: 120px;
            margin-bottom: 10px;
          }
        }

      }
      .new_icon{
        position: absolute;
        top:-5px;
        right: 40px;
        width: 20px;
      }



    }
    .panel:after {
      content: " ";
      width: 90%;
      border-bottom: solid 8px #CDCFD7;
      position: absolute;
      bottom: 0px;
      margin: 0  0 0 10px;
    }


  }

  dl.partnerCategory{
    border: 1px solid #ccc;
    padding: 20px 20px 10px;
    margin-top: 30px;
    dt{
      @include fontsize(22);
      font-weight: bold;
      color: #000;
      float: left;
    }
    dd{
      margin-left: 150px;
      ul{
        li{
          display: inline-block;
          border-right: 1px solid #ccc;
          a{
            padding: 0 10px;
            text-decoration: underline;


            &:hover{
              color: #fd8c7a;
              text-decoration: underline;
            }
          }

        }

      }

    }
  }


}