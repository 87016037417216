section.pagettl{
  margin-top: 60px;
  background-color: #ffa861;
  .pagettlDetail{
    padding-top: 20px;
    h2{
      @include fontsize(50);
      color: #fff;
      margin: 0 0 0;
    }
    p.lead{
      color: #fff;
      @include fontsize(20);
    }
    p.btn_category{
      a{
        display: block;
        background-color: #fff;
        border-radius: 50px;
        color: #ffb966;
        padding: 10px;
        text-align: center;
        &:hover{
          background-color: #CDCDCD;
          color: #fff;
          text-decoration: none;
        }
        i{
          margin-right: 5px;
        }
      }
    }

    p.btn_partner{
      a{
        display: block;
        background-color: #337ab7;
        border-radius: 50px;
        color: #fff;
        padding: 10px;
        text-align: center;
        &:hover{
          background-color: #CDCDCD;
          color: #fff;
          text-decoration: none;
        }
        i{
          margin-right: 5px;
        }
      }
    }

  }

}