section.categoryDetail{
  background-color: #efefef;
  padding: 10px 0 30px;

  .categryDblock{
    background-color: #fff;
    height: 150px;
    @media #{$small} {
      height: auto;
    }
    position: relative;
    margin-top: 30px;


    a{
      display: block;
      padding: 20px;
      height: 100%;
      background-color: #fff;
      color: #333;
      &:hover{
        background-color: #0b0b0b;
        text-decoration: none;
        color: #fff;
      }

      dl{
        margin-right: 100px;
        @media #{$small} {
          margin-right: 0;
        }
        dt{
          float: left;
          @include fontsize(60);
          color: #ffb966;
          margin-top: -10px;

        }
        dd{
          margin-left: 50px;
          ul{
            li{
              &.qTtl{
                font-weight: bold;
                @include fontsize(24);
                line-height: 1.5;
                margin-bottom: 10px;

              }
              &.qDetail{
                @include fontsize(20);
                line-height: 1.5;


              }

            }
          }


        }
      }

    }
    .new_icon{
      position: absolute;
      top:-5px;
      right: 20px;
      width: 20px;
    }

    .partnerRes{
      position: absolute;
      bottom: 0px;
      width: 100%;
      text-align: center;
      background-color: #337ab7;
      color: #fff;
      @include fontsize(20);
      padding: 3px 0;
      span{
        font-weight: bold;
        margin-left: 5px;
      }
    }



  }

  ul{


    li{
      margin-bottom: 5px;
      @media #{$small} {
        display: inline-block;
      }





      &.postname{
        font-weight: bold;
        @include fontsize(18);
        color: #909090;
        margin-top: 10px;


      }
      &.posttime{
        font-weight: bold;
        @include fontsize(18);
        color: #909090;
      }
      &.nicepoint{
        @include fontsize(18);
        color: #ffa861;
        span{
          font-weight: bold;
          margin-left: 5px;
        }
      }
      i{
        margin-right: 5px;
      }

    }

  }


  .categryDblock:after {
    content: " ";
    width: 95%;
    border-bottom: solid 8px #898989;
    position: absolute;
    bottom: -8px;
    margin: 0 0 0 25px;

  }

}