section.eyeCatch {
  margin-top: 60px;
  background-image: url("images/icatch.png");
  background-position: center;
  background-size: cover;
  position: relative;

  &:before {
    z-index: 0;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:rgba(0,0,0,0.25);
  }

  .icatch_lead{
    padding: 120px 0 0 0;
    p{
      color: #fff;
      @include fontsize(70);
      font-weight: bold;
      text-shadow: 3px 3px 0 #000;
      margin: 0 0 30px;
    }
    dl{
      dt{
        float: left;
        a{
          display: block;
          background: linear-gradient(-135deg, #50bba3, #9cdf86);
          color: #fff;
          border-radius: 50px;
          text-decoration: none;
          letter-spacing: 1px;
          text-align: center;
          padding: 10px 60px;
          width: 100%;
          @include fontsize(30);

          &:hover{
            background: linear-gradient(-135deg, #69fcdb, #aefe93);
            color: #fff;
            text-decoration: none;
          }
          i{
            margin-right: 15px;
          }
        }
      }
      dd{
        margin-left: 200px;
        a{
          display: block;
          background: linear-gradient(-135deg, #4b91c8, #5cc1c0);
          color: #fff;
          border-radius: 50px;
          text-decoration: none;
          letter-spacing: 1px;
          text-align: center;
          padding: 10px 20px;
          width: 100%;
          @include fontsize(30);
          font-weight: bold;

          &:hover{
            background: linear-gradient(-135deg, #5eb6fc, #78faf9);
            color: #fff;
            text-decoration: none;
          }
          i{
            margin-right: 15px;
          }
        }

      }
    }
  }

  .icatch_form{
    margin-top: 0px;
    background-image: url("images/icatch_form.png");
    background-position: center;
    background-size: cover;
    position: relative;
    padding: 20px 20px 20px;

    h2{
      margin-top: 0;
      margin-bottom: 20px;
      text-align: center;
      color: #fff;
      font-weight: bold;
      @include fontsize(30);
      i{
        margin-right: 10px;
        color: #337ab7;
        @include fontsize(40);
      }

    }
    .well{
      padding: 0px;
      margin-bottom: 0px;
      background-color: transparent;
      border: none;
      border-radius: 0;
    }
    form label {
      color: #fff;
      text-shadow: 1px 1px 0 #000;
    }

    button i{
      margin-left: 10px;
    }

    }




}



